import { Component } from "react";
import styles from "./Frame.css";
import classes from "classnames";

class Frame extends Component {
    render() {
        const {
            type = "full",
            children,
            className,
            id,
            frameRef,
        } = this.props || {};

        return (
            <section
                id={id}
                className={classes(className, type && styles[type])}
                ref={frameRef}
            >
                {children}
            </section>
        );
    }
}

export default Frame;
