import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import debounce from "debounce";

import {
    backgroundColorHover,
    backgroundColorLevelCurrent,
    fontSize16,
    lineHeight,
    sans,
    spacing4,
    spacing8,
    textColorPrimary,
} from "@churchofjesuschrist/eden-style-constants";

const TypeaheadContainer = styled.div`
    position: ${(props) => (props.contain ? "relative" : "static")};
`;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    margin-inline-start: 10px;
    position: absolute;
`;

const DefaultInput = styled.input`
    color: ${textColorPrimary};
    background-color: ${backgroundColorLevelCurrent};
    border-radius: 2px;
    font-family: ${sans};
    font-size: ${fontSize16};
    line-height: ${lineHeight};
    padding-inline: ${({ icon }) =>
        icon ? `40px ${spacing8}` : `${spacing8}`};
    padding-block: ${spacing4};
    width: 100%;
    min-width: 0px;
    background-repeat: no-repeat;
    background-size: 1.5rem 1.5rem;
    background-position: right ${spacing8} top ${spacing8};
    border: 1px solid rgb(135, 138, 140);
    margin: 0px;
`;

const ResultList = styled.ul`
    align-items: flex-start;
    background-color: ${backgroundColorLevelCurrent};
    bottom: ${({ reversed }) =>
        reversed ? `calc(100% + ${spacing8})` : "auto"};
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: ${({ reversed }) =>
        reversed ? "column-reverse" : "column"};
    font-family: ${sans};
    inset-inline-start: 0px;
    margin-block-start: ${spacing4};
    max-height: 420px;
    min-width: 50%;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 30;

    @supports (max-height: 40svh) {
        max-height: 40svh;
    }
`;

const ResultItem = styled.li`
    background: ${({ focused }) => (focused ? backgroundColorHover : "revert")};
    font-family: ${sans};
    font-size: ${fontSize16};
    line-height: ${lineHeight};
    padding: 10px;
    width: 100%;

    &:focus,
    &:hover {
        background: ${backgroundColorHover};
        cursor: pointer;
    }
`;

const defaultSearchKeys = ["title"];
const defaultResultsModifier = (items, results) => results;

export const fuseSortFn = (a, b) => {
    const aWordLength = Object.keys(a.item || {}).length && a.item[0].v?.length;
    const bWordLength = Object.keys(a.item || {}).length && b.item[0].v?.length;

    return a.score === b.score && aWordLength && bWordLength
        ? aWordLength - bWordLength
        : a.score - b.score;
};

const TypeAhead = ({
    autoFocus,
    className,
    contain = true,
    Icon,
    items = [],
    minChars = 2,
    renderId,
    onSubmit = () => {},
    placeholder,
    resultsModifier = defaultResultsModifier,
    searchKeys = defaultSearchKeys,
    fuseOptions,
}) => {
    const [active, setActive] = useState(false);
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [reversed, setReversed] = useState(false);
    const fuse = useRef();
    const container = useRef();
    const input = useRef();
    const list = useRef();

    // If we have a renderId that means the Typeahead is in a component that can change
    //   context while not rerendering, this sets the focus whenever that context changes.
    useEffect(() => {
        renderId &&
            setTimeout(() => {
                input.current.focus();
            });
    }, [renderId]);

    useEffect(() => {
        const options = {
            // https://fusejs.io/api/options.html
            ignoreLocation: true,
            includeScore: true,
            sortFn: fuseSortFn,
            useExtendedSearch: true,
            ...fuseOptions,
            keys: searchKeys,
        };

        fuse.current = new Fuse(items, options);
    }, [items, fuseOptions, searchKeys]);

    useLayoutEffect(() => {
        const containerRects = container.current.getBoundingClientRect();
        const listRects = list.current.getBoundingClientRect();

        if (containerRects.bottom + listRects.height > window.innerHeight) {
            setReversed(true);
            list.current.scrollTop = list.current.scrollHeight;
        } else {
            setReversed(false);
            list.current.scrollTop = 0;
        }
    }, [active, results]);

    const handleSetResults = useMemo(
        () =>
            debounce((value) => {
                const extendedSearchValue = value
                    .split(" ")
                    .reduce((string, word) => {
                        if (word.length === 0) {
                            return string;
                        }

                        return string === ""
                            ? `'${word}`
                            : `${string} '${word}`;
                    }, "");

                let results = fuse.current
                    .search(extendedSearchValue)
                    .map(({ item }) => item)
                    .filter(Boolean);

                results = resultsModifier(items, results, value);

                setResults(results);
            }, 100),
        [items, resultsModifier]
    );

    useEffect(() => {
        if (active && value.length >= minChars) {
            handleSetResults(value);
            setActiveIndex(0);
        } else if (value.length < minChars) {
            setResults([]);
            setActive(false);
            setActiveIndex(0);
        }
    }, [active, handleSetResults, minChars, value]);

    const reset = () => {
        setActive(false);
        setActiveIndex(0);
        setResults([]);
        setValue("");
    };

    const handleSubmit = (item) => {
        reset();
        onSubmit(item);

        // The input loses it's cursor on a submit, this will reapply it so a user can
        //   continue searching/adding tags
        input.current.blur();
        setTimeout(() => {
            input.current.focus();
        });
    };

    const getResultElements = () =>
        active && list.current && Array.from(list.current.children);

    const moveToResult = (previous = false) => {
        if (!active) return;

        let tempIndex = activeIndex;

        const resultElements = getResultElements();
        // determine the direction we're moving through the results using the NOR gate https://en.wikipedia.org/wiki/NOR_gate
        const direction = reversed ^ previous;
        const currentResult =
            ((direction ? --tempIndex : ++tempIndex) + resultElements.length) %
            resultElements.length;
        const resultElement = resultElements[currentResult];

        setActiveIndex(
            currentResult >= 0 && currentResult < resultElements.length
                ? currentResult
                : 0
        );
        resultElement.scrollIntoView({ block: "nearest", inline: "nearest" });
    };

    const handleComponentOnBlur = (event) => {
        if (
            !event.relatedTarget ||
            !event.currentTarget.contains(event.relatedTarget)
        ) {
            reset();
        } else {
            input.current.focus();
        }
    };

    const handleOnKeyDown = (event) => {
        switch (event.keyCode || event.which) {
            // tab
            case 9:
                if (active) {
                    event.preventDefault();
                    moveToResult(event.shiftKey);
                }

                break;

            // down
            case 40:
                event.stopPropagation();
                event.preventDefault();
                moveToResult(false);

                break;

            //up
            case 38:
                event.stopPropagation();
                event.preventDefault();
                moveToResult(true);

                break;

            //enter
            case 13: {
                event.preventDefault();
                let result = results[activeIndex];

                if (result) {
                    handleSubmit(result);
                }

                break;
            }

            //escape
            case 27:
                event.preventDefault();
                reset();

                break;

            default:
                break;
        }
    };

    const handleChange = (event) => {
        if (event.target.value === value) return;

        setActive(true);
        setValue(event.target.value);
    };

    return (
        <TypeaheadContainer
            className={className}
            contain={contain}
            onBlur={handleComponentOnBlur}
            ref={container}
        >
            {Icon && (
                <IconWrapper>
                    <Icon size="1.4em" />
                </IconWrapper>
            )}
            <DefaultInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                icon={!!Icon}
                onChange={handleChange}
                onFocus={() => setActive(true)}
                onKeyDown={handleOnKeyDown}
                placeholder={placeholder}
                ref={input}
                value={value}
            />
            <ResultList reversed={reversed} ref={list}>
                {active &&
                    results?.map((result, i) => (
                        <ResultItem
                            focused={i === activeIndex}
                            id={`${result.title}-${i}`}
                            key={`${result.title}-${i}`}
                            onClick={() => handleSubmit(result)}
                            tabIndex="-1"
                        >
                            {result.preTitle || ""} {result.title || ""}{" "}
                            {result.postTitle || ""}
                        </ResultItem>
                    ))}
            </ResultList>
        </TypeaheadContainer>
    );
};

export default TypeAhead;
